import React, { Component } from 'react';
import { login } from '../../util/APIUtils';
import './Login.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN } from '../../constants';
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import { Form, Input, Button, notification} from 'antd';
import {LockOutlined, UserOutlined} from "@ant-design/icons";
const FormItem = Form.Item;

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {


                const loginRequest = Object.assign({}, values);
                login(loginRequest)
                    .then(response => {
                        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                        this.props.onLogin();
                    }).catch(error => {
                    if(error.status === 401) {
                        notification.error({
                            message: 'ediMiss App',
                            description: 'Your Username or Password is incorrect. Please try again!'
                        });
                    } else {
                        notification.error({
                            message: 'ediMiss App',
                            description: error.message || 'Sorry! Something went wrong. Please try again!'
                        });
                    }
                });

    }

    render() {


        return (
            <div className="login-container">
                <h1 className="page-title">Login</h1>
                <div className="login-content">
                    <Form onFinish={this.handleSubmit} className="login-form" >
                        <FormItem name={"usernameOrEmail"} rules={[{ required: true, message: 'Please input your username or email!' }]}>
                            <Input
                                prefix={<UserOutlined />}
                                size="large"
                                name="usernameOrEmail"
                                placeholder="Username or Email" />

                        </FormItem>
                        <FormItem name={"password"} rules={[{ required: true, message: 'Please input your Password!' }]}>
                            <Input
                                prefix={<LockOutlined />}
                                size="large"
                                name="password"
                                type="password"
                                placeholder="Password"  />
                        </FormItem>
                        <FormItem>
                            <Button type="primary" htmlType="submit" size="large" className="login-form-button">Login</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        );
    }
}


export default Login;
