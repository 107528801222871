import React, {Component} from "react";
import {deleteUser, getRoles, getUsers} from "../util/APIUtils";
import {Button, Checkbox, Form, Input, Modal, notification, PageHeader, Popconfirm, Table, Tag} from "antd";
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {DeleteOutlined, EditOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import FormItem from "antd/es/form/FormItem";
import {EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, NAME_MIN_LENGTH} from "../constants";


class  UsersList extends Component{
    constructor(props) {
        super(props);
        this.state={
            users:[],
            isLoading:false,
            searchText: '',
            searchedColumn: '',
            visibleEdit:false,
            user:{},
            roles:[],
            name: {
                value: ''
            },
            username: {
                value: ''
            },
            email: {
                value: ''
            },
            rolesForSubmit:[],
        }

        this.loadUsers = this.loadUsers.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showEdit = this.showEdit.bind(this);
        this.loadRoles = this.loadRoles.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.isFormInvalid = this.isFormInvalid.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    loadRoles(){
        this.setState({
            isLoading: true
        });

        getRoles()
            .then(response => {
                this.setState({
                    roles: response,
                    isLoading: false
                });
            }).catch(error => {
            if(error.status === 404) {
                this.setState({
                    notFound: true,
                    isLoading: false
                });
            } else {
                this.setState({
                    serverError: true,
                    isLoading: false
                });
            }
        });
    }

    loadUsers(){
        let promise;
        promise = getUsers();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    users: response ,
                    isLoading: false,
                });

            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    handleDelete(userId){

        const deleteRequest = {
            id: userId,
        };
        deleteUser(deleteRequest)
            .then(response => {
                notification.success({
                    message: 'ediMiss App',
                    description: response.message,
                });

                this.loadUsers();
            }).catch(error => {
            notification.error({
                message: 'ediMiss App',
                description: error.message || 'Sorry! Something went wrong. Please try again!'
            });
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const updateRequest = {
            id: this.state.user.id,
            name: this.state.name.value,
            email: this.state.email.value,
            roles: this.state.rolesForSubmit
        };
        // updateUser(updateRequest)
        //     .then(response => {
        //         notification.success({
        //             message: 'ediMiss App',
        //             description: "User updated successfully!",
        //         });
        //         this.setState({
        //             visibleEdit:false,
        //         })
        //         this.loadUsers();
        //     }).catch(error => {
        //     notification.error({
        //         message: 'ediMiss App',
        //         description: error.message || 'Sorry! Something went wrong. Please try again!'
        //     });
        // });
    }

    showEdit(user){
        this.setState({
            user:user,
            visibleEdit:true,
            name: {
                value: user.name
            },
            username: {
                value: user.username
            },
            email: {
                value: user.email
            },
            rolesForSubmit:user.roles,
        })
        this.loadRoles();
    }

    handleCancel(){
        this.setState({
            visibleEdit:false,
        })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };


    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleCheck(event, id){
        const roleId = parseInt(id);
        if(event.target.checked===false && this.state.rolesForSubmit.some(role=> role.id === roleId)!=null){

                const newRoleList = this.state.rolesForSubmit.filter(e=>e.id !== roleId);
                this.setState({
                    rolesForSubmit:newRoleList,
                })

        }else{
            this.setState({
                rolesForSubmit:[
                    ...this.state.rolesForSubmit,
                    {id:roleId},
                ]
            });
        }
    }

    componentDidMount() {
        this.loadUsers();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    // icon={<SearchOutlined />}
                    icon={"search"}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                        this.setState({
                            searchText: selectedKeys[0],
                            searchedColumn: dataIndex,
                        });
                    }}
                >
                    Filter
                </Button>

            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleInputChange(event, validationFun) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName] : {
                value: inputValue,
                ...validationFun(inputValue)
            }
        });
    }

    // Validation Functions

    validateName = (name) => {
        if(name.length < NAME_MIN_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Name is too short (Minimum ${NAME_MIN_LENGTH} characters needed.)`
            }
        } else if (name.length > NAME_MAX_LENGTH) {
            return {
                validationStatus: 'error',
                errorMsg: `Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
            }
        } else {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
    }

    validateEmail = (email) => {
        if(!email) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email may not be empty'
            }
        }

        const EMAIL_REGEX = RegExp('[^@ ]+@[^@ ]+\\.[^@ ]+');
        if(!EMAIL_REGEX.test(email)) {
            return {
                validateStatus: 'error',
                errorMsg: 'Email not valid'
            }
        }

        if(email.length > EMAIL_MAX_LENGTH) {
            return {
                validateStatus: 'error',
                errorMsg: `Email is too long (Maximum ${EMAIL_MAX_LENGTH} characters allowed)`
            }
        }

        return {
            validateStatus: null,
            errorMsg: null
        }
    }


    isFormInvalid() {
        return !(this.state.name.validateStatus === 'success' &&
            this.state.email.validateStatus === 'success'
        );
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',

                ...this.getColumnSearchProps('name'),
            },
            {

                title: "Info",
                render: (record) => (
                    <React.Fragment>
                        {record.email}
                        <br />
                        {record.username}
                    </React.Fragment>
                ),
                responsive: ["xs"]
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                responsive: ["sm"],
                ...this.getColumnSearchProps('email'),
            },
            {
                title: 'Username',
                dataIndex: 'username',
                key: 'username',
                responsive: ["sm"],
                ...this.getColumnSearchProps('username'),
            },
            {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (roles) => (
                    <>
                        {roles?.map(tag => {
                                    return (
                                        <Tag color={'blue'} key={tag.id} onClick={()=>{}}  >
                                            {tag.name.toUpperCase()}
                                        </Tag>
                                    );
                            }
                        )}
                    </>
                ),
            },
            {
              render: (user) => (
                  <>
                      <a onClick={()=>{this.showEdit(user)}}>
                          <EditOutlined />
                      </a>
                      {this.props.currentUser.authorities.some(item => item.authority === "ROLE_ADMIN") ? (
                          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={()=>{this.handleDelete(user.id)}}>
                              <a  style={{color:"#f12323", marginLeft:"20px"}}>
                                  <DeleteOutlined />
                              </a>
                          </Popconfirm>
                      ):null}

                  </>
              )
            },


        ];

        return(
            <div>
                <div style={{display:"inline-block", width:"100%"}}>
                    <PageHeader
                        style={{display:"inline-block"}}
                        className="site-page-header"
                        title="Users"
                    />
                    <Link to={"/user/create"} style={{display:"inline-block",float:"right", padding:"20px"}}>
                        <Button
                            className={"buttonAdd"}
                            size="large">
                            Create User
                        </Button>
                    </Link>
                </div>
                <Table columns={columns} dataSource={this.state.users} rowKey={"id"} />
                <Modal title="User Edit" visible={this.state.visibleEdit} onOk={this.handleSubmit} onCancel={this.handleCancel} footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="edit" type="primary" loading={this.state.isLoading} onClick={this.handleSubmit}>
                        Submit
                    </Button>,
                ]}>

                    <Form onSubmit={()=>{}} className="create-poll-form">
                        <FormItem
                            label="Full Name"
                            validateStatus={this.state.name.validateStatus}
                            help={this.state.name.errorMsg}>
                            <Input
                                size="large"
                                name="name"
                                autoComplete="off"
                                placeholder="Your full name"
                                value={this.state.name.value}
                                onChange={(event) => this.handleInputChange(event, this.validateName)} />
                        </FormItem>
                        <FormItem label="Username"
                                  hasFeedback
                                  validateStatus={this.state.username.validateStatus}
                                  help={this.state.username.errorMsg}>
                            <Input
                                disabled={true}
                                size="large"
                                name="username"
                                autoComplete="off"
                                placeholder="A unique username"
                                value={this.state.username.value}
                                onChange={(event) => this.handleInputChange(event, this.validateUsername)} />
                        </FormItem>
                        <FormItem
                            label="Email"
                            hasFeedback
                            validateStatus={this.state.email.validateStatus}
                            help={this.state.email.errorMsg}>
                            <Input
                                size="large"
                                name="email"
                                type="email"
                                autoComplete="off"
                                placeholder="Your email"
                                value={this.state.email.value}
                                onChange={(event) => this.handleInputChange(event, this.validateEmail)} />
                        </FormItem>
                        <FormItem
                            label="Roles"
                            validateStatus={this.state.name.validateStatus}
                            help={this.state.name.errorMsg}>
                            {this.state.roles?.map(role=> role.id===1 ?
                                (<div key={role.id}><Checkbox disabled defaultChecked >{role.name}</Checkbox></div>)
                                :(<div key={role.id}><Checkbox onChange={(event)=>this.handleCheck(event,role.id)} checked={this.state.rolesForSubmit?.some(e=> e.id ===role.id)}>{role.name}</Checkbox></div>))}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        )
    }
}
export default UsersList;
