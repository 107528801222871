import React from 'react';
import { Spin} from 'antd';
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {LoadingOutlined} from "@ant-design/icons";


export default function LoadingIndicator(props) {
    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
        <Spin indicator={antIcon} style = {{display: 'block', textAlign: 'center', marginTop: 30}} />
    );
}
