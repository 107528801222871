import React, {Component} from "react";
import './NewProduct.css';
import FormItem from "antd/es/form/FormItem";
import {Button, Checkbox, Form, Image, Input, notification, Spin} from "antd";

import {API_BASE_URL, API_BASE_URL_PHOTO, MAX_CHOICES} from "../constants";
import {createProduct,deleteProductImage, updateProduct} from "../util/APIUtils";
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";
import Upload from "rc-upload";

class NewProduct extends Component{
    constructor(props) {
        super(props);
        this.state={
            product: props.product!=null ? props.product : {},
            productCustomFields: props.product!=null ? props.product.productCustomFields : [],
            isLoading:false,
            productImage: props.product!=null ? props.product.productImage : null,

        }

        this.addProductCustomField = this.addProductCustomField.bind(this);
        this.removeProductCustomField = this.removeProductCustomField.bind(this);
        this.handleProductCustomFieldCommentChange = this.handleProductCustomFieldCommentChange.bind(this);
        this.handleProductCustomFieldNameChange = this.handleProductCustomFieldNameChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleSupplierChange = this.handleSupplierChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
    }

    componentDidMount() {

    }

    addProductCustomField(event) {
        const productCustomFields = this.state.productCustomFields.slice();
        this.setState({
            productCustomFields: productCustomFields.concat([{
                name: '',
                comment: '',
            }])
        });
    }

    removeProductCustomField(productCustomFieldNumber) {
        const productCustomFields = this.state.productCustomFields.slice();
        this.setState({
            productCustomFields: [...productCustomFields.slice(0, productCustomFieldNumber), ...productCustomFields.slice(productCustomFieldNumber+1)]
        });
    }

    handleNameChange(event) {
        const value = event.target.value;
        this.setState({
            product:{
                ...this.state.product,
                name:value,
            }
        });
    }
    handleSupplierChange(event) {
        const value = event.target.value;
        this.setState({
            product:{
                ...this.state.product,
                supplier:value,
            }
        });
    }
    handleQuantityChange(event) {
        const value = event.target.value;
        this.setState({
            product:{
                ...this.state.product,
                quantity:value,
            }
        });
    }
    handleUnitChange(event) {
        const value = event.target.value;
        this.setState({
            product:{
                ...this.state.product,
                unit:value,
            }
        });
    }

    handlePriceChange(event) {
        const value = event.target.value;
        this.setState({
            product:{
                ...this.state.product,
                price:value,
            }
        });
    }

    handleProductCustomFieldNameChange(event, index) {
        const productCustomFields = this.state.productCustomFields?.slice();
        const value = event.target.value;

        productCustomFields[index] = {
            ...productCustomFields[index],
            name: value,

        }

        this.setState({
            productCustomFields: productCustomFields
        });
    }
    handleProductCustomFieldCommentChange(event, index) {
        const productCustomFields = this.state.productCustomFields?.slice();
        const value = event.target.value;

        productCustomFields[index] = {
            ...productCustomFields[index],
            comment: value,
        }

        this.setState({
            productCustomFields: productCustomFields
        });
    }

    handleSubmit() {
        const productData = {
            ...this.state.product,
            productCustomFields: this.state.productCustomFields?.map(productCustomField => {
                return {name: productCustomField.name, comment: productCustomField.comment}
            }),
            productImageId: this.state.productImage?.id,
        };

        if(this.props.product!=null) {
            console.log(this.state.product)
            updateProduct(productData)
                .then(response => {
                    this.props.handleCancel();
                    this.props.reloadProducts();
                }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'ediMiss App',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
        }else{
            createProduct(productData)
                .then(response => {
                    this.props.history.push("/product/list");
                }).catch(error => {
                if (error.status === 401) {
                    this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
                } else {
                    notification.error({
                        message: 'ediMiss App',
                        description: error.message || 'Sorry! Something went wrong. Please try again!'
                    });
                }
            });
        }
    }

    onSuccessHandle=(event)=>{
        this.setState({
            productImage:event
        })
    }

    imageComponent=(item)=>{
        return(
            <div style={{border:"1px solid #efefef", padding:"10px", marginTop:"20px"}}>
                <div className={"imageBox"}>
                    <Image
                        width={100}
                        src={item.url}
                    />
                    <div style={{float:"right", fontSize:"35px", color:"#a40000", cursor:"pointer"}}>
                        <CloseOutlined onClick={()=>this.handleFileRemove(item.id)}/>
                    </div>
                </div>
            </div>
        );
    }

    handleFileRemove=(fileId)=>{
        const fileData = {
            id: parseInt(fileId)
        };
        deleteProductImage(fileData)
            .then(response => {
                this.setState({
                    productImage:null,
                })
                notification.success({
                    message: 'ediMiss App',
                    description: response.message,
                });

            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    handleFinishCheckbox=(event)=>{
        const value = event.target.checked
        this.setState({
            product:{
                ...this.state.product,
                finished: value,
            }
        })
    }

    render(){

        const productCustomFieldViews = [];
        this.state.productCustomFields?.forEach((productCustomField, index) => {
            productCustomFieldViews.push(<ProductCustomField key={index} productCustomField={productCustomField} productCustomFieldNumber={index} removeProductCustomField={this.removeProductCustomField}  handleProductCustomFieldNameChange={this.handleProductCustomFieldNameChange} handleProductCustomFieldCommentChange={this.handleProductCustomFieldCommentChange}/>);
        });

        return(
            <Spin spinning={this.state.isLoading}>
                <div className="new-poll-container">
                    {this.props.product!=null?null:(
                        <h1 className="page-title">Create New Product</h1>
                    )}
                    <div className="new-poll-content">
                        <Form onFinish={this.handleSubmit} className="create-poll-form" initialValues={this.state.product}>
                            <FormItem className="poll-form-row">
                                {this.state.productImage!=null ? this.imageComponent(this.state.productImage):(
                                    <Upload
                                        action={API_BASE_URL_PHOTO+'/file/upload/productImage'}
                                        onSuccess={this.onSuccessHandle}
                                        multiple={true}
                                    ><Button className={"uploadButton"}>Upload Image</Button></Upload>
                                )}
                            </FormItem>

                            {this.props.product!=null?(
                                <FormItem  className="poll-form-row" >
                                    <Checkbox
                                        style = {{ fontSize: '16px' }}
                                        size="large"
                                        value = {this.state.product?.finished}
                                        onChange = {this.handleFinishCheckbox} >
                                        Ended Up
                                    </Checkbox>
                                </FormItem>
                            ):null}
                            <FormItem  className="poll-form-row" name={"name"} rules={[{ required: true, message: 'Please enter product name!' }]}>
                                <Input
                                    placeholder="Enter Product Name"
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    name={"name"}
                                    value = {this.state.product?.name}
                                    onChange = {this.handleNameChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row" name={"supplier"} rules={[{ required: true, message: 'Please enter product supplier!' }]}>
                                <Input
                                    placeholder="Enter Supplier"
                                    name={"supplier"}
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    value = {this.state.product?.supplier}
                                    onChange = {this.handleSupplierChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Quantity"
                                    style = {{ fontSize: '16px' }}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    size="large"
                                    type={"number"}
                                    value = {this.state.product?.quantity}
                                    onChange = {this.handleQuantityChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Unit"
                                    style = {{ fontSize: '16px' }}
                                    size="large"
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    value = {this.state.product?.unit}
                                    onChange = {this.handleUnitChange} />
                            </FormItem>
                            <FormItem  className="poll-form-row">
                                <Input
                                    placeholder="Enter Price"
                                    style = {{ fontSize: '16px' }}
                                    size="large"
                                    type={"number"}
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    value = {this.state.product?.price}
                                    onChange = {this.handlePriceChange} />
                            </FormItem>

                            {productCustomFieldViews}
                            <FormItem className="poll-form-row">
                                <Button type="dashed" onClick={this.addProductCustomField} disabled={this.state.productCustomFields.length === MAX_CHOICES}>
                                    <PlusOutlined /> Add a custom product field
                                </Button>
                            </FormItem>
                            <FormItem className="poll-form-row">
                                <Button type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="create-poll-form-button">{this.props.product!=null?("Update Product"):("Create Product")}</Button>
                            </FormItem>

                        </Form>
                    </div>
                </div>
            </Spin>
        )
    }
}

function ProductCustomField(props) {
    return (
        <FormItem  className="poll-form-row" style={{display:"inline"}}>
            <Input
                placeholder = {'Field Name ' + (props.productCustomFieldNumber + 1)}
                size="large"
                style={{display:"inline", width:"30%", marginRight:"10px"}}
                value={props.productCustomField.name}
                // className={ props.productCustomFieldNumber > 0 ? "optional-choice": null}
                onChange={(event) => props.handleProductCustomFieldNameChange(event, props.productCustomFieldNumber)} />
            <Input
                placeholder = {'Field Comment ' + (props.productCustomFieldNumber + 1)}
                size="large"
                style={{display:"inline", width:"60%"}}
                value={props.productCustomField.comment}
                className={ props.productCustomFieldNumber >= 0 ? "optional-choice": null}
                onChange={(event) => props.handleProductCustomFieldCommentChange(event, props.productCustomFieldNumber)} />
            <CloseOutlined className="dynamic-delete-button" onClick={() => props.removeProductCustomField(props.productCustomFieldNumber)} />


        </FormItem>
    );
}
export default NewProduct;
