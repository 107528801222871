import { API_BASE_URL, POLL_LIST_SIZE, ACCESS_TOKEN } from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};




export function getAllUsers() {
    return request({
        url: API_BASE_URL + "/allUsers",
        method: 'GET'
    });
}


export function login(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}

export function signup(signupRequest) {
    return request({
        url: API_BASE_URL + "/auth/signup",
        method: 'POST',
        body: JSON.stringify(signupRequest)
    });
}


export function checkUsernameAvailability(username) {
    return request({
        url: API_BASE_URL + "/user/checkUsernameAvailability?username=" + username,
        method: 'GET'
    });
}

export function checkEmailAvailability(email) {
    return request({
        url: API_BASE_URL + "/user/checkEmailAvailability?email=" + email,
        method: 'GET'
    });
}


export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/user/me",
        method: 'GET'
    });
}

export function getUserProfile(username) {
    return request({
        url: API_BASE_URL + "/users/" + username,
        method: 'GET'
    });
}


export function getUsers(){
    return request({
        url: API_BASE_URL + "/user/getUsers",
        method: 'GET'
    });
}

export function getRoles(){
    return request({
        url: API_BASE_URL + "/user/getRoles",
        method: 'GET'
    });
}

export function deleteUser(userData) {
    return request({
        url: API_BASE_URL + "/auth/deleteUser",
        method: 'POST',
        body: JSON.stringify(userData)
    });
}

export function getAllIllnesses() {

    return request({
        url: API_BASE_URL + "/illness/list",
        method: 'GET'
    });
}

export function getAllIllnessesSelect() {

    return request({
        url: API_BASE_URL + "/illness/listSelect",
        method: 'GET'
    });
}

export function createIllness(illnessData){
    return request({
        url: API_BASE_URL + "/illness/add",
        method: 'POST',
        body: JSON.stringify(illnessData)
    });
}

export function getAllProducts() {

    return request({
        url: API_BASE_URL + "/product/list",
        method: 'GET'
    });
}

export function getAllProductsForList() {

    return request({
        url: API_BASE_URL + "/product/product/list",
        method: 'GET'
    });
}
export function getProduct(productId) {

    return request({
        url: API_BASE_URL + "/product/product/"+productId,
        method: 'GET'
    });
}

export function createProduct(productData){
    return request({
        url: API_BASE_URL + "/product/add",
        method: 'POST',
        body: JSON.stringify(productData)
    });
}

export function updateProduct(productData){
    return request({
        url: API_BASE_URL + "/product/update",
        method: 'POST',
        body: JSON.stringify(productData)
    });
}

export function getAllCustomers() {

    return request({
        url: API_BASE_URL + "/customer/list",
        method: 'GET'
    });
}

export function getAllCustomersList() {
    return request({
        url: API_BASE_URL + "/customer/list/selection",
        method: 'GET'
    });
}

export function createCustomer(customerData){
    return request({
        url: API_BASE_URL + "/customer/add",
        method: 'POST',
        body: JSON.stringify(customerData)
    });
}

export function updateCustomer(customerData){
    return request({
        url: API_BASE_URL + "/customer/update",
        method: 'POST',
        body: JSON.stringify(customerData)
    });
}

export function createVisitation(visitationData){
    return request({
        url: API_BASE_URL + "/visitation/add",
        method: 'POST',
        body: JSON.stringify(visitationData)
    });
}

export function getCustomer(customerId){
    return request({
        url: API_BASE_URL + "/customer/"+customerId,
        method: 'GET'
    });
}

export function getVisitations(customerId){
    return request({
        url: API_BASE_URL + "/visitation/list/"+customerId,
        method: 'GET'
    });
}

export function createEvent(eventData){
    return request({
        url: API_BASE_URL + "/event/add",
        method: 'POST',
        body: JSON.stringify(eventData)
    });
}

export function updateEvent(eventData){
    return request({
        url: API_BASE_URL + "/event/update",
        method: 'POST',
        body: JSON.stringify(eventData)
    });
}

export function getAllEvents() {
    return request({
        url: API_BASE_URL + "/event/list",
        method: 'GET'
    });
}

export function deleteEvent(eventData){
    return request({
        url: API_BASE_URL + "/event/delete",
        method: 'POST',
        body: JSON.stringify(eventData)
    });
}

export function updateVisitation(visitationData){
    return request({
        url: API_BASE_URL + "/visitation/update",
        method: 'POST',
        body: JSON.stringify(visitationData)
    });
}

export function deleteFile(fileData){
    return request({
        url: API_BASE_URL + "/file/delete",
        method: 'POST',
        body: JSON.stringify(fileData)
    });
}

export function getVisitation(visitationId) {
    return request({
        url: API_BASE_URL + "/visitation/item/"+visitationId,
        method: 'GET'
    });
}

export function getFileAttachments(visitationId) {
    return request({
        url: API_BASE_URL + "/visitation/file/"+visitationId,
        method: 'GET'
    });
}
export function deleteCustomerPicture(fileData){
    return request({
        url: API_BASE_URL + "/file/delete/customerPicture",
        method: 'POST',
        body: JSON.stringify(fileData)
    });
}

export function deleteProductImage(fileData){
    return request({
        url: API_BASE_URL + "/file/delete/productImage",
        method: 'POST',
        body: JSON.stringify(fileData)
    });
}

export function getChartEarningData(chartData) {
    return request({
        url: API_BASE_URL + "/chart/earnings",
        method: 'POST',
        body: JSON.stringify(chartData)
    });
}
export function existVisitationByEvent(eventId) {
    return request({
        url: API_BASE_URL + "/event/eventHasVisitation/"+eventId,
        method: 'GET'
    });
}

export function createVisitationFromEvent(eventData){
    return request({
        url: API_BASE_URL + "/event/createVisitation",
        method: 'POST',
        body: JSON.stringify(eventData)
    });
}


