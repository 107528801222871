import React, { Component } from 'react';
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {
    Link,
    withRouter
} from 'react-router-dom';
import './AppHeader.css';
import {Layout, Menu, Dropdown, Space} from 'antd';
import {
    CalendarOutlined,
    DisconnectOutlined, DownOutlined,
    DropboxOutlined,
    IdcardOutlined, MenuOutlined,
    UsergroupAddOutlined, UserOutlined
} from "@ant-design/icons";
const Header = Layout.Header;

let widthScreen = window.innerWidth;
window.onresize = (event) => {
    widthScreen = window.innerWidth;
};

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading:false,
            width: window.innerWidth,
        };
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth
        });
    }

    handleMenuClick({ key }) {
      if(key === "logout") {
        this.props.onLogout();
      }
    }

    render() {



        let menuItems;
        if(this.props.currentUser) {
          menuItems =
              this.props.currentUser.authorities.some(item => item.authority === "ROLE_ADMIN") ?
                   [
                       {
                           label:(<Link to="/user/list">
                               <UsergroupAddOutlined /> Users
                           </Link>),
                           key:"/user/list"
                       },
                       {
                           label:(
                               <Link to="/calendar">
                                   <CalendarOutlined /> Calendar
                               </Link>
                           ),
                           key:"/calendar"
                       },
                       {
                           label:(
                               <Link to="/customer/list">
                                   <IdcardOutlined /> Customers
                               </Link>
                           ),
                           key:"/customer/list"
                       },
                       {
                           label:(
                               <Link to="/product/list">
                                   <DropboxOutlined /> Products
                               </Link>
                           ),
                           key:"/product/list"
                       },
                       {
                           label:(
                               <Link to="/illness/list">
                                   <DisconnectOutlined /> Illnesses
                               </Link>
                           ),
                           key:"/illness/list"
                       },



                       {
                            label:(<div className={"profile-menu"}>
                                <ProfileDropdownMenu
                                    className={"menu-item"}
                                    currentUser={this.props.currentUser}
                                    handleMenuClick={this.handleMenuClick}/>
                            </div>)
                        }

          ]  : [
                      {
                          label:(<Link to="/calendar">
                              <CalendarOutlined />
                          </Link>),
                          key: "/calendar",
                      },
                      {
                          label:(<Link to="/customer/list">
                              <IdcardOutlined />
                          </Link>),
                          key: "/customer/list"
                      },
                      {
                          label:(<Link to="/product/list">
                              <DropboxOutlined />
                          </Link>),
                          key:"/product/list"
                      },
                      {
                          label:(<Link to="/illness/list">
                              <DisconnectOutlined />
                          </Link>),
                          key:"/illness/list"
                      },



                      {
                          label: (<div className={"profile-menu"}>
                              <ProfileDropdownMenu
                                  className={"menu-item"}
                                  currentUser={this.props.currentUser}
                                  handleMenuClick={this.handleMenuClick}/>
                          </div>)

                      }
                  ];
        } else {
          menuItems = [
              {label:(
                      <Link to="/login">Login</Link>
                  )}
          ];
        }

        const menu = (
            <Menu
                style={{width:"200px", padding:"10px"}}
                onClick={this.handleMenuClick}
                items={[
                    {
                        label:(<Link to="/user/list" >
                            <UsergroupAddOutlined className={"menuItemMobile"}/> Users
                        </Link>),
                        key:"/user/list"
                    },
                    {
                        label:(
                            <Link to="/calendar">
                                <CalendarOutlined className={"menuItemMobile"}/> Calendar
                            </Link>
                        ),
                        key:"/calendar"
                    },
                    {
                        label:(
                            <Link to="/customer/list">
                                <IdcardOutlined className={"menuItemMobile"}/> Customers
                            </Link>
                        ),
                        key:"/customer/list"
                    },
                    {
                        label:(
                            <Link to="/product/list">
                                <DropboxOutlined className={"menuItemMobile"}/> Products
                            </Link>
                        ),
                        key:"/product/list"
                    },
                    {
                        label:(
                            <Link to="/illness/list">
                                <DisconnectOutlined className={"menuItemMobile"}/> Illnesses
                            </Link>
                        ),
                        key:"/illness/list"
                    },



                    {
                        label:(<div className={"profile-menu menuItemMobile"} >
                            Logout
                        </div>),
                        key:"logout"
                    }
                ]}
            />
        );


        return (
            <Header className="app-header">
            <div className="container">
              <div className="app-title" >
                <Link to="/">
                    <a>ediMiss</a>
                </Link>
              </div>
                {this.state.width > 800? (
                    <Menu
                        mode="horizontal"
                        selectedKeys={[this.props.location.pathname]}
                        style={{ lineHeight: '64px', float:"right", width:"700px", marginRight:"-100px"}}
                        items={menuItems}>
                    </Menu>
                ):(<Menu
                    mode="horizontal"
                    selectedKeys={[this.props.location.pathname]}
                    style={{ lineHeight: '64px', float:"right"}}
                    >
                    <Dropdown trigger={['click']} overlay={menu}>
                        <a onClick={e => e.preventDefault()}>
                            <Space>
                                <MenuOutlined />
                            </Space>
                        </a>
                    </Dropdown>
                </Menu>)}


            </div>
          </Header>
        );
    }
}



function ProfileDropdownMenu(props) {


  const dropdownMenu = (
    <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu" >
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div className="user-full-name-info">
          {props.currentUser.name}
        </div>
        <div className="username-info">
          @{props.currentUser.username}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="profile" className="dropdown-item" >
        <Link to={`/users/${props.currentUser.username}`}>Profile</Link>
      </Menu.Item>

      <Menu.Item key="logout" className="dropdown-item">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      getPopupContainer = { () => document.getElementsByClassName('profile-menu')[0]}>
          <a className="ant-dropdown-link">
              <UserOutlined className="nav-icon menu-item" style={{marginRight: 0}}/>  <DownOutlined />
          </a>
    </Dropdown>
  );
}

function DropdownMenu(props) {


    const dropdownMenu = (
        <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu" >
            <Menu.Item key="user-info" className="dropdown-item" disabled>
                <div className="user-full-name-info">
                    {props.currentUser.name}
                </div>
                <div className="username-info">
                    @{props.currentUser.username}
                </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="profile" className="dropdown-item" >
                <Link to={`/users/${props.currentUser.username}`}>Profile</Link>
            </Menu.Item>

            <Menu.Item key="logout" className="dropdown-item">
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={dropdownMenu}
            trigger={['click']}
            getPopupContainer = { () => document.getElementsByClassName('profile-menu')[0]}>
            <a className="ant-dropdown-link">
                <UserOutlined className="nav-icon menu-item" style={{marginRight: 0}}/>  <DownOutlined />
            </a>
        </Dropdown>
    );
}




export default withRouter(AppHeader);
