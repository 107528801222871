import {Component} from "react";
import {Button, Form, Input, notification} from "antd";
// import 'antd/dist/antd.less';
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/es/input/TextArea";
import {createIllness} from "../util/APIUtils";

class NewIllness extends Component{
    constructor(props) {
        super(props);
        this.state={
            name: '',
            about:'',
        }

        this.handleNameInput = this.handleNameInput.bind(this);
        this.handleAboutInput = this.handleAboutInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

    }

    handleNameInput=(event)=>{
        this.setState({
            name: event.target.value
        })
    }

    handleAboutInput=(event)=>{
        this.setState({
            about: event.target.value
        })
    }

    handleSubmit(){

        const illnessData = {
            name: this.state.name,
            about: this.state.about,
        };

        createIllness(illnessData)
            .then(response => {
                this.props.history.push("/illness/list");
            }).catch(error => {
            if(error.status === 401) {
                this.props.handleLogout('/login', 'error', 'You have been logged out. Please login create poll.');
            } else {
                notification.error({
                    message: 'ediMiss App',
                    description: error.message || 'Sorry! Something went wrong. Please try again!'
                });
            }
        });
    }

    render(){
        return(
            <div className="new-poll-container">
                <h1 className="page-title">Create Illness</h1>
                <div className="new-poll-content">
                    <Form onFinish={this.handleSubmit} className="create-poll-form">
                        <FormItem className="poll-form-row" name={"name"} rules={[{ required: true, message: 'Please enter name!' }]}>
                            <Input
                                placeholder="Enter Name"
                                name={"name"}
                                style = {{ fontSize: '16px' }}
                                value = {this.state.name}
                                onChange = {this.handleNameInput} />
                        </FormItem>
                        <FormItem className="poll-form-row">
                            <TextArea
                                placeholder="About the illness"
                                style = {{ fontSize: '16px' }}
                                autoSize={{ minRows: 3, maxRows: 6 }}
                                name = "name"
                                value = {this.state.about}
                                onChange = {this.handleAboutInput} />
                        </FormItem>

                        <FormItem className="poll-form-row">
                            <Button type="primary"
                                    htmlType="submit"
                                    size="large"
                                    className="create-poll-form-button">Create Illness</Button>
                        </FormItem>

                    </Form>
                </div>
            </div>
        )
    }
}
export default NewIllness;
