import React, {Component} from "react";
import {POLL_LIST_SIZE} from "../constants";
import {getAllCustomers, getAllProducts} from "../util/APIUtils";
import {Button, Image, Input, PageHeader, Spin, Table, Tag} from "antd";
// import 'antd/dist/antd.less';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {Link, Redirect} from "react-router-dom";

import "./CustomerList.css";

class CustomerList extends Component{
    constructor(props) {
        super(props);
        this.state={
            searchText: '',
            searchedColumn: '',
            customers:null,
            isLoading: false,
            doRedirect:false,
            customerId:null,
        }

        this.loadCustomers = this.loadCustomers.bind(this);
    }

    loadCustomers(){
        let promise;
        promise = getAllCustomers();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    customers: response,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentDidMount() {
        this.loadCustomers();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    // icon={<SearchOutlined />}
                    icon={"search"}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                        this.setState({
                            searchText: selectedKeys[0],
                            searchedColumn: dataIndex,
                        });
                    }}
                >
                    Filter
                </Button>

            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };


    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleRedirectToCustomerCard=(customerId)=>{
        this.setState({
            customerId: customerId,
            doRedirect:true,
        })
    }

    render(){

        const columns = [
            {
                dataIndex: 'customerPicture',
                width: 50,
                render:(r)=>{
                    return(
                        <Image
                            preview={false}
                            className={"customerListImage"}
                            src={r?.url!=null?r.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
                        />
                    );
                }}
            ,
            {
                title: 'Name',
                dataIndex: 'fullName',
                key: 'fullName',
                // width: '30%',
                ...this.getColumnSearchProps('fullName'),
            },
            {
                title: "Info",
                render: (record) => (
                    <React.Fragment>
                        {record.phoneNumber}
                        <br />
                        {record.email}
                    </React.Fragment>
                ),
                responsive: ["xs"]
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber',
                // width: '30%',
                ...this.getColumnSearchProps('phoneNumber'),
                responsive: ["sm"],
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                // width: '30%',
                ...this.getColumnSearchProps('email'),
                responsive: ["sm"],
            },
        ];
        if (this.state.doRedirect) {
             return(<Redirect to={"/customer/"+this.state.customerId}/>)
        }
            return (
                <div>
                    <Spin spinning={this.state.isLoading}>
                        <div style={{display: "inline-block", width: "100%"}}>
                            <PageHeader
                                style={{display: "inline-block"}}
                                className="site-page-header"
                                title="Customers"
                            />
                            <Link to={"/customer/new"}
                                  style={{display: "inline-block", float: "right", padding: "20px"}}>
                                <Button
                                    className={"buttonAdd"}
                                    size="large">
                                    Add New Customer
                                </Button>
                            </Link>
                        </div>
                        <Table columns={columns} dataSource={this.state.customers} rowKey={"id"} pagination={{ defaultPageSize: 5}} onRow={(r) => ({
                            onClick: () => this.handleRedirectToCustomerCard(r.id),
                        })}/>
                    </Spin>
                </div>
            )

    }
}
export default CustomerList;
