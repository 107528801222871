import React, {Component} from "react";
import {POLL_LIST_SIZE} from "../constants";
import {getAllProducts} from "../util/APIUtils";
import {Button, Image, Input, PageHeader, Spin, Table, Tag} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {Link} from "react-router-dom";
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import Modal from "antd/es/modal/Modal";
import NewProduct from "./NewProduct";

class ProductList extends Component{
    constructor(props) {
        super(props);
        this.state={
            searchText: '',
            searchedColumn: '',
            products:null,
            isLoading: false,
            productModalView:false,
            product:null,
            productModalEdit:false,
        }

        this.loadProducts = this.loadProducts.bind(this);
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts(){
        let promise;
        promise = getAllProducts();

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    products: response,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    // icon={<SearchOutlined />}
                    icon={"search"}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({ closeDropdown: false });
                        this.setState({
                            searchText: selectedKeys[0],
                            searchedColumn: dataIndex,
                        });
                    }}
                >
                    Filter
                </Button>

            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };


    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleCancel=()=>{
        this.setState({
            productModalView:false,
            productModalEdit:false,
        })
    }

    handleProductViewOpen=(event)=>{
        this.setState({
            product:event,
            productModalView:true,
        })
    }

    handleProductEditOpen=()=>{
        this.setState({
            productModalEdit:true,
            productModalView:false,
        })
    }

    render(){

        const columns = [
            {
                dataIndex: 'productImage',
                width: 50,
                render:(r)=>{
                    return(
                        <Image
                            width={50}
                            preview={false}
                            style={{borderRadius:"25px"}}
                            src={r?.url!=null?r.url:"https://basanaviciausprogimnazija.lt/wp-content/uploads/2018/04/no-user-image.gif"}
                        />
                    );
                }
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                // width: '30%',
                ...this.getColumnSearchProps('name'),
            },
            {
                title: 'Supplier',
                dataIndex: 'supplier',
                key: 'supplier',
                // width: '30%',
                ...this.getColumnSearchProps('supplier'),
            },
            {
                title: 'Ended Up',
                dataIndex: 'finished',
                key: 'finished',
                // width: '30%',
                render:(r)=>{
                    if(r){
                        return(<div>Yes</div>)
                    }else{
                        return (<div>No</div>)
                    }
                }
            },
        ];

        return(
            <div>
                <Spin spinning={this.state.isLoading}>
                    <div style={{display:"inline-block", width:"100%"}}>
                        <PageHeader
                            style={{display:"inline-block"}}
                            className="site-page-header"
                            title="Products"
                        />
                        <Link to={"/product/new"} style={{display:"inline-block",float:"right", padding:"20px"}}>
                            <Button
                                className={"buttonAdd"}
                                size="large">
                                Add New Product
                            </Button>
                        </Link>
                    </div>
                    <Table columns={columns} dataSource={this.state.products} rowKey={"id"} onRow={(r) => ({
                        onClick: () => this.handleProductViewOpen(r),
                    })}/>

                    <Modal visible={this.state.productModalView} title="Product View"  onCancel={this.handleCancel}
                        footer={[
                            <Button key="back" onClick={this.handleCancel}>
                                Cancel
                            </Button>,
                            <Button  type="primary" onClick={this.handleProductEditOpen}>
                                Edit
                            </Button>,
                        ]}
                    >
                        <div style={{textAlign:"center", background:"#e4b5ff", borderRadius:"20px"}}><h2 style={{color:"#fff"}}>{this.state.product?.name}</h2></div>
                        {this.state.product?.productImage!=null?(
                            <div style={{textAlign:"center"}}><Image
                                width={200}
                                preview={true}
                                style={{borderRadius:"20px"}}
                                src={this.state.product?.productImage?.url}
                            /></div>
                        ):null}

                        <div>
                            <div><strong>Supplier: </strong> {this.state.product?.supplier}</div>
                            {this.state.product?.quantity!=null?(
                                <div><strong>Quantity: </strong> {this.state.product?.quantity} {this.state.product?.unit}</div>
                            ):null}
                            {this.state.product?.price!=null?(
                                <div><strong>Price: </strong> {this.state.product?.price}</div>
                            ):null}
                            {this.state.product?.productCustomFields?.map(item=>(
                                <div><strong>{item.name}: </strong> {item.comment}</div>
                            ))}
                        </div>

                    </Modal>
                    <Modal visible={this.state.productModalEdit} title="Product Edit"  onCancel={this.handleCancel}
                           footer={[
                               <Button key="back" onClick={this.handleCancel}>
                                   Cancel
                               </Button>,

                           ]}
                    >
                        <NewProduct product={this.state.product} handleCancel={this.handleCancel} reloadProducts={this.loadProducts}/>

                    </Modal>
                </Spin>
            </div>
        )
    }
}
export default ProductList;
