import React, {Component} from "react";
import './FirstPage.css';
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';

import {Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import {getChartEarningData} from "../util/APIUtils";
import {Button} from "antd";

let widthScreen = window.innerWidth;
window.onresize = (event) => {
    widthScreen = window.innerWidth;
};


class FirstPage extends Component{
    constructor(props) {
        super(props);
        this.state={
            earningData:null,
            isLoading: false,
            year: new Date().getFullYear(),
            month: new Date().getMonth()+1,
            mode:"month",
            width: window.innerWidth,
        }

        this.loadChartEarningData = this.loadChartEarningData.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    loadChartEarningData(){

        const chartData={
            mode: this.state.mode,
            year:this.state.year,
            month:this.state.month,
        }
        console.log(chartData)
        let promise;
        promise = getChartEarningData(chartData);

        this.setState({
            isLoading: true
        });

        promise
            .then(response => {
                this.setState({
                    earningData: response,
                    isLoading: false
                });
            }).catch(error => {
            this.setState({
                isLoading: false
            })
        });
    }

    componentDidMount() {
        this.loadChartEarningData();
        window.addEventListener("resize", this.updateDimensions);
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.mode !== this.state.mode) {
            this.loadChartEarningData();
        }
        if (prevState.year !== this.state.year) {
            this.loadChartEarningData();
        }
        if (prevState.month !== this.state.month) {
            this.loadChartEarningData();
        }

    }


    handleThisYear=()=>{
        this.setState({
            year: new Date().getFullYear(),
        })

    }
    handlePreviousYear=()=>{
        this.setState({
            year:this.state.year - 1,
        })
    }
    handleThisMonth=()=>{
        this.setState({
            month: new Date().getMonth()+1,
            year: new Date().getFullYear(),
        })
    }
    handlePreviousMonth=()=>{
        let monthValue = this.state.month - 1;
        if(monthValue === 0){
            this.setState({
                month: 12,
                year: this.state.year - 1,
            })
        }else {
            this.setState({
                month: monthValue,
            })
        }
    }

    handleByMonth=()=>{
        this.setState({
            mode: "month"
        })
    }

    handleByDays=()=>{
        this.setState({
            mode: "day"
        })
    }


    render() {

        let widthInpercent = this.state.width * 85 / 100;

        return (
            <div >
                <div style={{display:"inline-block", textAlign:"center", width:"100%", fontSize:"20px"}}>{this.state.year}-{this.state.month}</div>
                <div style={{display:"inline-block", width:"100%", marginBottom:"10px"}}>

                    {this.state.mode==="month"?(
                        <div style={{display:"inline-block"}}>
                            <Button onClick={this.handlePreviousYear}>Previous Year</Button>
                            <Button onClick={this.handleThisYear}>This Year</Button>

                        </div>
                    ):(
                        <div style={{display:"inline-block"}}>
                            <Button onClick={this.handlePreviousMonth}>Previous Month</Button>
                            <Button onClick={this.handleThisMonth}>This Month</Button>

                        </div>
                    )}
                    <div style={{display:"inline-block", float:"right"}}>
                        {this.state.mode==="month"?(
                            <Button onClick={this.handleByDays}>By Days</Button>
                        ):( <Button onClick={this.handleByMonth}>By Month</Button>)}


                    </div>
                </div>

                <BarChart width={widthInpercent} height={250} data={this.state.earningData} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="money" fill="#8884d8" />
                    <Bar dataKey="expense" fill="#ff0303" />
                    <Bar dataKey="visitations" fill="#ff6c6c" />
                </BarChart>



            </div>
        )
    }

}


export default FirstPage;
