import React, { Component } from 'react';
import './App.css';
import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom';

import { getCurrentUser } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';

import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
import Profile from '../user/profile/Profile';
import AppHeader from '../common/AppHeader';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import {Layout, Menu, notification} from 'antd';
import FirstPage from "../common/FirstPage";
import UsersList from "../user/UsersList";
import IllnessList from "../illness/IllnessList";
import NewIllness from "../illness/NewIllness";
import NewProduct from "../products/NewProduct";
import ProductList from "../products/ProductList";
import NewCustomer from "../customer/NewCustomer";
import CustomerList from "../customer/CustomerList";
import CustomerCard from "../customer/CustomerCard";
import CalendarSch from "../calendar/CalendarSch";
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isAuthenticated: false,
      isLoading: true,

    }
    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);


    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  loadCurrentUser() {
    getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });
    });
  }



  componentDidMount() {
    this.loadCurrentUser();
  }

  handleLogout(redirectTo="/login", notificationType="success", description="You're successfully logged out.") {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    this.props.history.push(redirectTo);

    notification[notificationType]({
      message: 'ediMiss App',
      description: description,
    });
  }


  handleLogin() {
    notification.success({
      message: 'ediMiss App',
      description: "You're successfully logged in.",
    });
    this.loadCurrentUser();
    this.props.history.push("/");
  }

  render() {
    if(this.state.isLoading) {
      return <LoadingIndicator />
    }

    return (
        <Layout className="app-container">
          <AppHeader isAuthenticated={this.state.isAuthenticated}
            currentUser={this.state.currentUser}
            onLogout={this.handleLogout}
                     messageState={this.state.messageState}
          />
          <Content className="app-content">
            <div className="container">
              <Switch>
                <Route exact path="/"
                  render={(props) => this.state.isAuthenticated ? (<FirstPage currentUser={this.state.currentUser}/>):(<Login onLogin={this.handleLogin} {...props} />)}>
                </Route>
                <Route path="/login"  render={(props) => <Login onLogin={this.handleLogin} {...props} />}></Route>
                <Route path="/users/:username"
                  render={(props) => <Profile isAuthenticated={this.state.isAuthenticated} currentUser={this.state.currentUser} {...props}  />}>
                </Route>
                {this.state.currentUser?.authorities?.some(item=> item.authority ==="ROLE_ADMIN") ? (
                    <PrivateRoute authenticated={this.state.isAuthenticated} path="/user/create" component={Signup} handleLogout={this.handleLogout} ></PrivateRoute>
                ):null}
                {this.state.currentUser?.authorities?.some(item=> item.authority ==="ROLE_ADMIN") ? (
                    <PrivateRoute authenticated={this.state.isAuthenticated} path="/user/list" currentUser={this.state.currentUser} component={UsersList} handleLogout={this.handleLogout} ></PrivateRoute>
                ):null}

                <PrivateRoute authenticated={this.state.isAuthenticated} path="/illness/list" component={IllnessList} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/illness/new" component={NewIllness} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/product/list" component={ProductList} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/product/new" component={NewProduct} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/customer/list" component={CustomerList} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/customer/new" component={NewCustomer} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/customer/:customerId" component={CustomerCard} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/calendar" component={CalendarSch} currentUser={this.state.currentUser} handleLogout={this.handleLogout}></PrivateRoute>

                <Route component={NotFound}></Route>
              </Switch>
            </div>
          </Content>
        </Layout>
    );
  }
}

export default withRouter(App);
